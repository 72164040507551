import { Divider, IconButton } from "@mui/material";
import { openConfirm } from "components/confirmBox";
import AddressDisplay from "components/fields/AddressDisplay";
import styled from "styled-components";
import { AddressType } from "types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  addressHistory: AddressType[];
  onDeleteAddress(address: AddressType): void;
  onEdit(addressHistoryItem: AddressType): void;
};

export default function AddressHistoryList({
  addressHistory,
  onDeleteAddress,
  onEdit,
}: Props) {
  return (
    <>
      {(!addressHistory ||
        (addressHistory && addressHistory?.length === 0)) && (
        <Message>
          No Address History. Click <b>Add</b> to Add Address
        </Message>
      )}

      {addressHistory && addressHistory.length > 0 && (
        <div>
          {addressHistory.map((e, index) => {
            return (
              <div style={{ marginTop: "2em" }} key={index}>
                <AddressHistoryItemRow>
                  <div style={{ flex: 1 }}>
                    <AddressDisplay key={index} address={e} />
                  </div>
                  <div>
                    <IconContainer>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          onEdit(e);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          openConfirm({
                            title: "Delete Address",
                            description: `Address: ${e.address}, ${e.city}, ${e.province}. Continue?`,
                            callback: () => {
                              onDeleteAddress(e);
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </IconContainer>
                    <DateDiv>
                      <BoldLabel>Address Start Date : </BoldLabel> {e.startDate}
                    </DateDiv>
                  </div>
                </AddressHistoryItemRow>
                <Divider style={{ marginTop: "2em" }} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

const Message = styled.div`
  margin: 5em auto;
`;

const AddressHistoryItemRow = styled.div`
  display: flex;
  min-width: 600px;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: right;
`;

const DateDiv = styled.div`
  margin-top: 1em;
  font-size: small;
  display: flex;
`;
const BoldLabel = styled.div`
  font-weight: bold;
  margin-right: 0.5em;
`;
