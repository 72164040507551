import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import {
  LessonData,
  Employer,
  PersonalClient,
  GeneralLedger,
  LessonListItem,
  SectionListItem,
  StudentListItem,
  StudentSessionLessonInfo,
  OutstandingOverridesTransaction,
  CustomStudent,
  BusinessClient,
  LessonSettings,
} from "types";
import { getAuthBearerToken } from "store/getAuthBearerToken";
import moment from "moment";

const controlName = "lesson";
const baseURL = `${process.env.REACT_APP_API_BASE}/lesson`;

type State = {
  lessonId: number;
  getLessonNameAndData: ReducerState<GetLessonNameAndDataReturn>;
  getStudentSessionLessonInfo: ReducerState<GetStudentSessionLessonInfoReturn>;
  getLessonList: ReducerState<LessonListItem[]>;
  addLesson: ReducerState<AddLessonReturn>;
  deleteLesson: ReducerState<DeleteLessonReturn>;
  resetLesson: ReducerState<ResetLessonReturn>;
  addSectionsToAllLessons: ReducerState<AddSectionsReturn>;
  getSectionAndStudentList: ReducerState<GetSectionAndStudentListReturn>;
  removeSection: ReducerState<RemoveSectionsReturn>;
  updateStudentAccess: ReducerState<UpdateStudentAccessReturn>;
  getDataforViewAsStudent: ReducerState<GetLessonDataforViewAsStudentReturn>;
  approveOverride: ReducerState<ApproveOverrideReturn>;
  rejectOverride: ReducerState<RejectOverrideReturn>;
  addCustomStudent: ReducerState<AddStudentReturn>;
  editLessonName: ReducerState<EditLessonNameReturn>;
  resetStudentLessonByInstructor: ReducerState<ResetLessonReturn>;
  updateTransactionsYear: ReducerState<updateTransactionsMonthsReturn>;
  saveLessonSettings: ReducerState<SaveLessonSettingsReturn>;
};

export type GetLessonNameAndDataReturn = {
  lessonName: string;
  lessonData: LessonData;
  isReadOnly: boolean;
  startDateTime: string;
  endDateTime: string;
};

let getLessonNameAndData = createAsyncAction<
  GetLessonNameAndDataReturn,
  { id: number; onComplete?(retValue: GetLessonNameAndDataReturn): void },
  State
>({
  actionPrefix: controlName,
  actionName: "getLessonNameAndData",
  url: baseURL + "/getLessonNameAndData",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    //state.lessonId = 0;
    state.lessonId =
      ((sessionStorage.getItem("lesson_id") as unknown) as number) ?? 0;
    state.getLessonNameAndData = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.lessonId = action.params.id;
    state.getLessonNameAndData.isLoading = false;
    state.getLessonNameAndData.data = action.payload;
  },
  rejected: (state, action) => {
    state.getLessonNameAndData.isLoading = false;
    state.getLessonNameAndData.error = action.payload;
  },
  onComplete: (payload) => {
    if ((payload.data as GetLessonNameAndDataReturn).lessonName) {
      sessionStorage.setItem("lesson_id", payload.params.id.toString());
    }
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as GetLessonNameAndDataReturn);
  },
});

export type updateTransactionsMonthsReturn = {
  success: boolean;
  message: string;
};

let updateTransactionsMonths = createAsyncAction<
  updateTransactionsMonthsReturn,
  {
    id: number;
    numberOfMonths: number;
    onComplete?(retValue: updateTransactionsMonthsReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "updateTransactionsMonths",
  url: baseURL + "/updateTransactionsMonths",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    //state.lessonId = 0;
    state.lessonId =
      ((sessionStorage.getItem("lesson_id") as unknown) as number) ?? 0;
    state.updateTransactionsYear = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.lessonId = action.params.id;
    state.updateTransactionsYear.isLoading = false;
    state.updateTransactionsYear.data = action.payload;
  },
  rejected: (state, action) => {
    state.updateTransactionsYear.isLoading = false;
    state.updateTransactionsYear.error = action.payload;
  },
  onComplete: (payload) => {
    if ((payload.data as updateTransactionsMonthsReturn).success) {
      sessionStorage.setItem("lesson_id", payload.params.id.toString());
    }
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as updateTransactionsMonthsReturn);
  },
});

type GetLessonDataforViewAsStudentReturn = {
  lessonName: string;
  lessonData: LessonData;
  studentName: string;
};

let getDataforViewAsStudent = createAsyncAction<
  GetLessonDataforViewAsStudentReturn,
  {
    id: number;
    studentId: string;
    onComplete?(retValue: GetLessonDataforViewAsStudentReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "getDataforViewAsStudent",
  url: baseURL + "/getDataforViewAsStudent",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    //state.lessonId = 0;
    state.lessonId =
      ((sessionStorage.getItem("lesson_id") as unknown) as number) ?? 0;
    state.getDataforViewAsStudent = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.lessonId = action.params.id;
    state.getDataforViewAsStudent.isLoading = false;
    state.getDataforViewAsStudent.data = action.payload;
  },
  rejected: (state, action) => {
    state.getDataforViewAsStudent.isLoading = false;
    state.getDataforViewAsStudent.error = action.payload;
  },
  onComplete: (payload) => {
    if ((payload.data as GetLessonDataforViewAsStudentReturn).lessonName) {
      sessionStorage.setItem("lesson_id", payload.params.id.toString());
    }
    payload.params.onComplete &&
      payload.params.onComplete(
        payload.data as GetLessonDataforViewAsStudentReturn
      );
  },
});

type GetStudentSessionLessonInfoReturn = {
  lessonInfo: StudentSessionLessonInfo;
};

let getStudentSessionLessonInfo = createAsyncAction<
  GetStudentSessionLessonInfoReturn,
  {
    id: number;
    onComplete?(retValue: GetStudentSessionLessonInfoReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "getStudentSessionLessonInfo",
  url: baseURL + "/getStudentSessionLessonInfo",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.getStudentSessionLessonInfo = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getStudentSessionLessonInfo.isLoading = false;
    state.getStudentSessionLessonInfo.data = action.payload;
  },
  rejected: (state, action) => {
    state.getStudentSessionLessonInfo.isLoading = false;
    state.getStudentSessionLessonInfo.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(
        payload.data as GetStudentSessionLessonInfoReturn
      );
  },
});

let getLessonList = createAsyncAction<
  LessonListItem[],
  { onComplete?(retValue: LessonListItem[]): void },
  State
>({
  actionPrefix: controlName,
  actionName: "getLessonList",
  url: baseURL + "/getLessonList",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.getLessonList = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getLessonList.isLoading = false;
    state.getLessonList.data = action.payload;
  },
  rejected: (state, action) => {
    state.getLessonList.isLoading = false;
    state.getLessonList.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as LessonListItem[]);
  },
});

type AddLessonReturn = {
  success: boolean;
  message: string;
};

let addLesson = createAsyncAction<
  AddLessonReturn, // returnType
  {
    lessonName: string;
    id: number;
    onComplete?(retValue: AddLessonReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "addLesson",
  url: baseURL + "/addLesson",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.addLesson = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.addLesson.isLoading = false;
    state.addLesson.data = action.payload;
  },
  rejected: (state, action) => {
    state.addLesson.isLoading = false;
    state.addLesson.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as AddLessonReturn);
  },
});

type EditLessonNameReturn = {
  success: boolean;
  message: string;
  lessonList: LessonListItem[];
};

let editLessonName = createAsyncAction<
  EditLessonNameReturn, // returnType
  {
    updatedLessonName: string;
    id: number;
    onComplete?(retValue: EditLessonNameReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "editLessonName",
  url: baseURL + "/editLessonName",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.addLesson = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.addLesson.isLoading = false;
    state.addLesson.data = action.payload;
  },
  rejected: (state, action) => {
    state.addLesson.isLoading = false;
    state.addLesson.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as EditLessonNameReturn);
  },
});

type DeleteLessonReturn = {
  success: boolean;
  message: string;
};

let deleteLesson = createAsyncAction<
  DeleteLessonReturn, // returnType
  { id: number; onComplete?(retValue: DeleteLessonReturn): void }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "deleteLesson",
  url: baseURL + "/deleteLesson",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.deleteLesson = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.deleteLesson.isLoading = false;
    state.deleteLesson.data = action.payload;
  },
  rejected: (state, action) => {
    state.deleteLesson.isLoading = false;
    state.deleteLesson.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as DeleteLessonReturn);
  },
});

type ResetLessonReturn = {
  success: boolean;
  message: string;
};

let resetLesson = createAsyncAction<
  ResetLessonReturn,
  { id: number; onComplete?(retValue: ResetLessonReturn): void },
  State
>({
  actionPrefix: controlName,
  actionName: "resetLesson",
  url: baseURL + "/resetLesson",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.lessonId = 0;
    state.resetLesson = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.lessonId = action.params.id;
    state.resetLesson.isLoading = false;
    state.resetLesson.data = action.payload;
  },
  rejected: (state, action) => {
    state.resetLesson.isLoading = false;
    state.resetLesson.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as ResetLessonReturn);
  },
});

let resetStudentLessonByInstructor = createAsyncAction<
  ResetLessonReturn,
  {
    studentId: string;
    lessonId: number;
    onComplete?(retValue: ResetLessonReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "resetStudentLessonByInstructor",
  url: baseURL + "/resetStudentLessonByInstructor",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.lessonId = 0;
    state.resetStudentLessonByInstructor = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.lessonId = action.params.lessonId;
    state.resetStudentLessonByInstructor.isLoading = false;
    state.resetStudentLessonByInstructor.data = action.payload;
  },
  rejected: (state, action) => {
    state.resetStudentLessonByInstructor.isLoading = false;
    state.resetStudentLessonByInstructor.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as ResetLessonReturn);
  },
});

type AddSectionsReturn = {
  success: boolean;
  message: string;
};

let addSectionsToAllLessons = createAsyncAction<
  AddSectionsReturn,
  {
    sectionList: SectionListItem[];
    onComplete?(retValue: AddSectionsReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "addSectionsToAllLessons",
  url: baseURL + "/addSectionsToAllLessons",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.addSectionsToAllLessons = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.addSectionsToAllLessons.isLoading = false;
    state.addSectionsToAllLessons.data = action.payload;
  },
  rejected: (state, action) => {
    state.addSectionsToAllLessons.isLoading = false;
    state.addSectionsToAllLessons.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as AddSectionsReturn);
  },
});

type UpdateStudentAccessReturn = {
  success: boolean;
  message: string;
};

let updateStudentAccess = createAsyncAction<
  UpdateStudentAccessReturn,
  {
    id: number;
    updatedStudentList: StudentListItem[];
    onComplete?(retValue: UpdateStudentAccessReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "updateStudentAccess",
  url: baseURL + "/updateStudentAccess",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.updateStudentAccess = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.updateStudentAccess.isLoading = false;
    state.updateStudentAccess.data = action.payload;
  },
  rejected: (state, action) => {
    state.updateStudentAccess.isLoading = false;
    state.updateStudentAccess.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as UpdateStudentAccessReturn);
  },
});

type RemoveSectionsReturn = {
  success: boolean;
  message: string;
};

let removeSection = createAsyncAction<
  RemoveSectionsReturn,
  {
    sectionId: string;
    id: number;
    onComplete?(retValue: RemoveSectionsReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "removeSection",
  url: baseURL + "/removeSection",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.removeSection = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.removeSection.isLoading = false;
    state.removeSection.data = action.payload;
  },
  rejected: (state, action) => {
    state.removeSection.isLoading = false;
    state.removeSection.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as RemoveSectionsReturn);
  },
});

type GetSectionAndStudentListReturn = {
  sectionList: SectionListItem[];
  studentList: StudentListItem[];
};

let getSectionAndStudentList = createAsyncAction<
  GetSectionAndStudentListReturn,
  { id: number; onComplete?(retValue: GetSectionAndStudentListReturn): void },
  State
>({
  actionPrefix: controlName,
  actionName: "getSectionAndStudentList",
  url: baseURL + "/getSectionAndStudentList",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.getSectionAndStudentList = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getSectionAndStudentList.isLoading = false;
    state.getSectionAndStudentList.data = action.payload;
  },
  rejected: (state, action) => {
    state.getSectionAndStudentList.isLoading = false;
    state.getSectionAndStudentList.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as GetSectionAndStudentListReturn);
  },
});

type ApproveOverrideReturn = {
  success: boolean;
  message: string;
};

let approveOverride = createAsyncAction<
  ApproveOverrideReturn, // returnType
  {
    overrideTransaction: OutstandingOverridesTransaction;
    id: number;
    onComplete?(retValue: ApproveOverrideReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "approveOverride",
  url: baseURL + "/approveOverride",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.approveOverride = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.approveOverride.isLoading = false;
    state.approveOverride.data = action.payload;
  },
  rejected: (state, action) => {
    state.approveOverride.isLoading = false;
    state.approveOverride.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as ApproveOverrideReturn);
  },
});

type RejectOverrideReturn = {
  success: boolean;
  message: string;
};

let rejectOverride = createAsyncAction<
  RejectOverrideReturn, // returnType
  {
    overrideTransaction: OutstandingOverridesTransaction;
    id: number;
    onComplete?(retValue: RejectOverrideReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "rejectOverride",
  url: baseURL + "/rejectOverride",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.rejectOverride = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.rejectOverride.isLoading = false;
    state.rejectOverride.data = action.payload;
  },
  rejected: (state, action) => {
    state.rejectOverride.isLoading = false;
    state.rejectOverride.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as RejectOverrideReturn);
  },
});

type AddStudentReturn = {
  studentList: StudentListItem[];
};

let addCustomStudent = createAsyncAction<
  AddStudentReturn, // returnType
  {
    student: CustomStudent;
    lessonId: number;
    onComplete?(retValue: AddStudentReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "addCustomStudent",
  url: baseURL + "/addCustomStudent",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.addCustomStudent = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.addCustomStudent.isLoading = false;
    state.addCustomStudent.data = action.payload;
  },
  rejected: (state, action) => {
    state.addCustomStudent.isLoading = false;
    state.addCustomStudent.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as AddStudentReturn);
  },
});

type SaveLessonSettingsReturn = {
  success: boolean;
  message: string;
};

let saveLessonSettings = createAsyncAction<
  SaveLessonSettingsReturn,
  {
    id: number;
    isTimeBasedAccess: boolean;
    startDateTime?: string;
    endDateTime?: string;
    onComplete?(retValue: SaveLessonSettingsReturn): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "saveLessonSettings",
  url: baseURL + "/saveLessonSettings",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.saveLessonSettings = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveLessonSettings.isLoading = false;
    state.saveLessonSettings.data = action.payload;
  },
  rejected: (state, action) => {
    state.saveLessonSettings.isLoading = false;
    state.saveLessonSettings.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as SaveLessonSettingsReturn);
  },
});

type SavePersonalClientAndGL = {
  updatedClient: PersonalClient;
  updatedGL: GeneralLedger;
};

type SaveBusinessClientAndGL = {
  updatedClient: BusinessClient;
  updatedGL: GeneralLedger;
};

type SaveInstructorFeedback = {
  clientId: string;
  feedback?: string;
};

let slice = createSlice({
  name: controlName,
  initialState: {
    lessonId: 0,
    getLessonNameAndData: { isLoading: false },
    getDataforViewAsStudent: { isLoading: false },
    getStudentSessionLessonInfo: { isLoading: false },
    getLessonList: { isLoading: false },
    addLesson: { isLoading: false },
    deleteLesson: { isLoading: false },
    resetLesson: { isLoading: false },
    addSectionsToAllLessons: { isLoading: false },
    getSectionAndStudentList: { isLoading: false },
    removeSection: { isLoading: false },
    updateStudentAccess: { isLoading: false },
    approveOverride: { isLoading: false },
    rejectOverride: { isLoading: false },
    addCustomStudent: { isLoading: false },
    editLessonName: { isLoading: false },
    resetStudentLessonByInstructor: { isLoading: false },
    updateTransactionsYear: { isLoading: false },
    saveLessonSettings: { isLoading: false },
  } as State,
  reducers: {
    saveEmployer: (state, data: { payload: Employer }) => {
      if (state.getLessonNameAndData.data) {
        const employers =
          state.getLessonNameAndData.data.lessonData.employers ?? [];
        state.getLessonNameAndData.data.lessonData.employers = employers.find(
          (e) => e.employerId === data.payload.employerId
        )
          ? employers.map((e) =>
              e.employerId === data.payload.employerId ? data.payload : e
            )
          : [...employers, data.payload];
      }
    },
    deleteEmployer: (state, data: { payload: string }) => {
      if (state.getLessonNameAndData.data) {
        const employers =
          state.getLessonNameAndData.data.lessonData.employers ?? [];
        state.getLessonNameAndData.data.lessonData.employers = employers.filter(
          (e) => e.employerId !== data.payload
        );
      }
    },
    savePersonalClient: (state, data: { payload: PersonalClient }) => {
      if (state.getLessonNameAndData.data) {
        const clients =
          state.getLessonNameAndData.data.lessonData.personalClients ?? [];
        state.getLessonNameAndData.data.lessonData.personalClients = clients.find(
          (e) =>
            e.identification?.clientId === data.payload.identification?.clientId
        )
          ? clients.map((e) =>
              e.identification?.clientId ===
              data.payload.identification?.clientId
                ? data.payload
                : e
            )
          : [...clients, data.payload];
      }
    },
    deletePersonalClient: (state, data: { payload: string }) => {
      if (state.getLessonNameAndData.data) {
        const clients =
          state.getLessonNameAndData.data.lessonData.personalClients ?? [];
        state.getLessonNameAndData.data.lessonData.personalClients = clients.filter(
          (e) => e.identification?.clientId !== data.payload
        );
      }
    },
    deleteBusinessClient: (state, data: { payload: string }) => {
      if (state.getLessonNameAndData.data) {
        const clients =
          state.getLessonNameAndData.data.lessonData.businessClients ?? [];
        state.getLessonNameAndData.data.lessonData.businessClients = clients.filter(
          (e) => e.information?.clientId !== data.payload
        );
      }
    },
    saveGeneralLedger: (state, data: { payload: GeneralLedger }) => {
      if (state.getLessonNameAndData.data) {
        state.getLessonNameAndData.data.lessonData.generalLedger = {
          ...data.payload,
        };
      }
    },
    savePersonalClientAndGL: (
      state,
      data: { payload: SavePersonalClientAndGL }
    ) => {
      if (state.getLessonNameAndData.data) {
        const clients =
          state.getLessonNameAndData.data.lessonData.personalClients ?? [];
        const currentstate = state.getLessonNameAndData.data.lessonData;
        state.getLessonNameAndData.data.lessonData = {
          ...currentstate,
          personalClients: clients.find(
            (e) =>
              e.identification?.clientId ===
              data.payload.updatedClient.identification?.clientId
          )
            ? clients.map((e) =>
                e.identification?.clientId ===
                data.payload.updatedClient.identification?.clientId
                  ? data.payload.updatedClient
                  : e
              )
            : [...clients, data.payload.updatedClient],
          generalLedger: data.payload.updatedGL,
        };
      }
    },
    saveBusinessClientAndGL: (
      state,
      data: { payload: SaveBusinessClientAndGL }
    ) => {
      if (state.getLessonNameAndData.data) {
        const clients =
          state.getLessonNameAndData.data.lessonData.businessClients ?? [];
        const currentstate = state.getLessonNameAndData.data.lessonData;
        state.getLessonNameAndData.data.lessonData = {
          ...currentstate,
          businessClients: clients.find(
            (e) =>
              e.information?.clientId ===
              data.payload.updatedClient.information?.clientId
          )
            ? clients.map((e) =>
                e.information?.clientId ===
                data.payload.updatedClient.information?.clientId
                  ? data.payload.updatedClient
                  : e
              )
            : [...clients, data.payload.updatedClient],
          generalLedger: data.payload.updatedGL,
        };
      }
    },
    removeLessonFromReduxLessonList: (state, data: { payload: number }) => {
      if (state.getLessonList.data) {
        const lessonList = state.getLessonList.data ?? [];
        state.getLessonList.data = lessonList.filter(
          (l) => l.id !== data.payload
        );
      }
    },
    updateReduxLessonList: (state, data: { payload: LessonListItem[] }) => {
      // if (state.getLessonList.data) {
      //const lessonList = state.getLessonList.data ?? [];
      state.getLessonList.data = data.payload;
      // lessonList.filter(
      //   (l) => l.id !== data.payload
      // );
      //}
    },
    updateReduxStudentList: (state, data: { payload: StudentListItem[] }) => {
      if (state.getStudentSessionLessonInfo.data) {
        state.getStudentSessionLessonInfo.data.lessonInfo.studentList =
          data.payload;
      }
    },
    saveBusinessClient: (state, data: { payload: BusinessClient }) => {
      if (state.getLessonNameAndData.data) {
        const clients =
          state.getLessonNameAndData.data.lessonData.businessClients ?? [];
        state.getLessonNameAndData.data.lessonData.businessClients = clients.find(
          (e) => e.information?.clientId === data.payload.information?.clientId
        )
          ? clients.map((e) =>
              e.information?.clientId === data.payload.information?.clientId
                ? data.payload
                : e
            )
          : [...clients, data.payload];
      }
    },
    updateLastModifiedReduxLessonList: (state, data: { payload: number }) => {
      if (state.getLessonList.data) {
        const currentDateTime = moment
          .utc()
          .format("YYYY-MM-DD h:mm:ss A")
          .toString();
        const lessonList = state.getLessonList.data ?? [];
        state.getLessonList.data = lessonList.map((l) =>
          l.id === data.payload ? { ...l, lastModified: currentDateTime } : l
        );
      }
    },
    saveInformationInstFeedbackForStudentPersonalClient: (
      state,
      data: { payload: SaveInstructorFeedback }
    ) => {
      if (state.getDataforViewAsStudent.data) {
        const clients =
          state.getDataforViewAsStudent.data.lessonData.personalClients ?? [];
        state.getDataforViewAsStudent.data.lessonData.personalClients = clients.find(
          (e) => e.identification?.clientId === data.payload.clientId
        )
          ? clients.map((e) =>
              e.identification?.clientId === data.payload.clientId
                ? { ...e, informationInstructorFeedback: data.payload.feedback }
                : e
            )
          : [...clients];
      }
    },
    saveCreditAppInstFeedbackForStudentPersonalClient: (
      state,
      data: { payload: SaveInstructorFeedback }
    ) => {
      if (state.getDataforViewAsStudent.data) {
        const clients =
          state.getDataforViewAsStudent.data.lessonData.personalClients ?? [];
        state.getDataforViewAsStudent.data.lessonData.personalClients = clients.find(
          (e) => e.identification?.clientId === data.payload.clientId
        )
          ? clients.map((e) =>
              e.identification?.clientId === data.payload.clientId
                ? { ...e, creditAppInstructorFeedback: data.payload.feedback }
                : e
            )
          : [...clients];
      }
    },

    saveInformationInstFeedbackForStudentBusinessClient: (
      state,
      data: { payload: SaveInstructorFeedback }
    ) => {
      if (state.getDataforViewAsStudent.data) {
        const clients =
          state.getDataforViewAsStudent.data.lessonData.businessClients ?? [];
        state.getDataforViewAsStudent.data.lessonData.businessClients = clients.find(
          (e) => e.information?.clientId === data.payload.clientId
        )
          ? clients.map((e) =>
              e.information?.clientId === data.payload.clientId
                ? { ...e, informationInstructorFeedback: data.payload.feedback }
                : e
            )
          : [...clients];
      }
    },
    updateReduxLessonSettings: (state, data: { payload: LessonSettings }) => {
      if (state.getStudentSessionLessonInfo.data) {
        state.getStudentSessionLessonInfo.data.lessonInfo.lessonSettings =
          data.payload;
      }
    },
  },
  extraReducers: {
    ...getLessonNameAndData.reducer,
    ...getDataforViewAsStudent.reducer,
    ...getLessonList.reducer,
    ...addLesson.reducer,
    ...deleteLesson.reducer,
    ...resetLesson.reducer,
    ...addSectionsToAllLessons.reducer,
    ...getStudentSessionLessonInfo.reducer,
    ...getSectionAndStudentList.reducer,
    ...removeSection.reducer,
    ...updateStudentAccess.reducer,
    ...approveOverride.reducer,
    ...rejectOverride.reducer,
    ...addCustomStudent.reducer,
    ...editLessonName.reducer,
    ...resetStudentLessonByInstructor.reducer,
    ...updateTransactionsMonths.reducer,
    ...saveLessonSettings.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getLessonNameAndData: getLessonNameAndData.action,
      getDataforViewAsStudent: getDataforViewAsStudent.action,
      getLessonList: getLessonList.action,
      addLesson: addLesson.action,
      deleteLesson: deleteLesson.action,
      resetLesson: resetLesson.action,
      addSectionsToAllLessons: addSectionsToAllLessons.action,
      getStudentSessionLessonInfo: getStudentSessionLessonInfo.action,
      getSectionAndStudentList: getSectionAndStudentList.action,
      removeSection: removeSection.action,
      updateStudentAccess: updateStudentAccess.action,
      approveOverride: approveOverride.action,
      rejectOverride: rejectOverride.action,
      addCustomStudent: addCustomStudent.action,
      editLessonName: editLessonName.action,
      resetStudentLessonByInstructor: resetStudentLessonByInstructor.action,
      updateTransactionsMonths: updateTransactionsMonths.action,
      saveLessonSettings: saveLessonSettings.action,
    },
  },
};

export default ret;
